const annotatinonsTemplate={
    "@context": [
      "https://www.w3.org/ns/anno.jsonld",
      "https://comments.acrobat.com/ns/anno.jsonld"
    ],
    "type": "Annotation",
    // "id": "02dcf931-d1cb-49c1-a8bc-d047892a06bc",
    // "bodyValue": "I added a text annotation!",
    "motivation": "commenting",
    "stylesheet": {
      "type": "CssStylesheet",
      "value": "body-value-css { font: 32px Helvetica; color: #ff0000; }"
    },
    "target": {
      "source": "6d07d124-ac85-43b3-a867-36930f502ac7",
      "selector": {
        "node": {
        //   "index": 0
       
      },
      "subtype": "note",
      // "boundingBox": [
      //     1056.86196083246276,
      //     1463.51466901912244,
      //     1074.16777499886447,
      //     1080.82048318552415
      // ],
    //   "boundingBox": [
    //     956.86196083246276,
    //     963.51466901912244,
    //     974.16777499886447,
    //     980.82048318552415
    // ],
    "boundingBox": [
      50.86196083246276,
      50.51466901912244,
      50.16777499886447,
      50.82048318552415
  ],
        "strokeColor": "#FA1919",
        "strokeWidth": 7,
        // "styleClass": "body-value-css",
        "type": "AdobeAnnoSelector"
      }
    },
    "creator": {
      "type": "Person",
      "name": "NarayanaIAS"
    },
    "created":new Date().toISOString(),
    "modified":new Date().toISOString(),
}

export default annotatinonsTemplate