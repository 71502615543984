import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Navigate, useNavigate } from "react-router-dom";
import UserContext from "../../UserContext";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import landing from "./landing.jpg";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { endpoint } from "../../apis";

const Login = (props) => {
  // clientID: 553125461546-ti2e6ul3g3n5ik3ohm2fr7ojoqebrlhq.apps.googleusercontent.com
  // client secret: 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
  const [showLoginProgressBar, setShowLoginProgressBar] = useState(false);
  const location = useLocation();
  let navigate = useNavigate();
  let [loggedInUserDetails, setLoggedInUserDetails] =
    React.useContext(UserContext);

  useEffect(() => {
    let name = Cookies.get("user_name");
    let user_id = Cookies.get("user_id");
    let email = Cookies.get("email");

    if (name && user_id && email) {
      let apiData = {
        name: name,
        user_id: user_id,
        email: email,
      };

      internalLogin(apiData, undefined, false);
    }
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      //
      setShowLoginProgressBar(true);
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        }
      );
      //
      let apiData = {
        name: userInfo.data.name,
        email: userInfo.data.email,
        domain: "narayanagroup",
      };
      internalLogin(apiData, userInfo, true);
    },
    onError: (errorResponse) => {
      setShowLoginProgressBar(false);
    },
  });

  // makes request to login_via_sso and checks if cookie has been set then redirects to /
  const internalLogin = async (data, userInfo, setCookie = false) => {
    try {
      let config = {
        method: "post",
        url: `${endpoint}/user/login_via_sso`,
        headers: {
          token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
          "Content-Type": "application/json",
        },
        data: data,
      };

      let response = await axios(config);
      setShowLoginProgressBar(false);

      if (setCookie) {
        setLoggedInUserDetails(
          Object.assign({}, userInfo.data, {
            user_id: response.data.user_id,
            premium: response.data.premium,
          })
        );
        Cookies.set("google_info", JSON.stringify(userInfo.data), {
          expires: 90,
        });
        Cookies.set("user_name", userInfo.data.name, { expires: 90 });
        Cookies.set("user_id", response.data.user_id, { expires: 90 });
        Cookies.set("email", userInfo.data.email, { expires: 90 });
        navigate(location.state ? location.state.from : "/");
      } else {
        // match user_if from response with the data stored in cookie
        if (response.data.user_id === data.user_id) {
          setLoggedInUserDetails(
            Object.assign({}, JSON.parse(Cookies.get("google_info")), {
              user_id: data.user_id,
              premium: response.data.premium,
            })
          );
          navigate(location.state ? location.state.from : "/");
        }
      }
    } catch (error) {
      setShowLoginProgressBar(false);
    }
  };

  return (
    <>
      {showLoginProgressBar ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          style={{ minHeight: "100vh" }}
        >
          <CircularProgress color="info" />
          {/* 
                        add text for loader here
                        <Typography></Typography>

                        */}
        </Box>
      ) : (
        <Grid container component="main" justifyContent="space-between">
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{ p: 4 }}
            justifyContent={"center"}
            alignItems="center"
          >
            <img
              src={landing}
              alt="Background"
              style={{
                width: "100%",
                // height: 500,
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Login
              </Typography>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => googleLogin()}
              >
                Log in
              </Button>

              {/* <Copyright sx={{ mt: 40 }} /> */}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Login;
