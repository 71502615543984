import axios from "axios";
import { apiData } from "./apis";
import Cookies from "js-cookie";
const token = "87957bdf-8fb3-4afc-a5c7-4ad62f785a60";
const DOMAIN = "narayanagroup";

async function loginViaSso(data) {
  try {
    let config = {
      method: "post",
      url: "https://collectorbabu.com/api/user/login_via_sso",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    let response = await axios(config);
  } catch (error) {}
}

async function mainsAnswerReview(
  formData,
  user_id,
  text,
  question_id,
  mode,
  manualQuestion
) {
  try {
    let params = {
      user_id: user_id,
      domain: DOMAIN,
    };

    // if (time_taken.length > 0) {
    //   params["time_taken"] = Number(time_taken);
    // }

    // if (mode === "manual") {
    params["question_text_custom"] = manualQuestion;
    // }

    // dont send question id for this one
    if (question_id) {
      // params["question_id"] = question_id;
    }

    if (!formData) {
      params["text"] = text;
      formData = new FormData();
      formData.append(`img_file1`, "");
      formData.append(`img_file2`, "");
      formData.append(`img_file3`, "");
    }

    let response = await axios.post(apiData.mainsAnswerReview, formData, {
      params: params,
      headers: {
        accept: "appication/json",
        "Content-Type": "multipart/form-data",
        token: token,
      },
    });

    return response;
  } catch (error) {
    console.log(error);
  }
}

const isLoggedIn = async () => {
  let name = Cookies.get("user_name");
  let user_id = Cookies.get("user_id");
  let email = Cookies.get("email");

  if (name && user_id && email) {
    let apiData = {
      name: name,
      user_id: user_id,
      email: email,
      domain: DOMAIN,
    };

    let userData = await internalLogin(apiData, undefined, false);
    return userData;
  }
};

const internalLogin = async (data, userInfo, setCookie = false) => {
  try {
    let config = {
      method: "post",
      url: apiData.loginViaSso,
      headers: {
        token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
        "Content-Type": "application/json",
      },
      data: data,
    };

    let response = await axios(config);

    return Object.assign({}, JSON.parse(Cookies.get("google_info")), {
      user_id: response.data.user_id,
      premium: response.data.premium,
    });
  } catch (error) {
    throw new Error(error);
  }
};

async function questionsForDay(day) {
  try {
    let data = {
      day: Number(day),
    };

    let response = await axios({
      method: "post",
      url: apiData.questionsForDay,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: data,
    });

    return response;
  } catch (error) {}
}

export { loginViaSso, mainsAnswerReview, isLoggedIn, questionsForDay };
